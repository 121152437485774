import React, { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './ProductCard.css';

const ProductCard = ({ catName, Name, image, descr, date_created }) => {
	const [showChar, setShowChar] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);

	const dateCreated = new Date(date_created); // замените на вашу дату
	const twoWeeksAgo = new Date();
	twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

	// Создаем placeholder изображение
	const placeholderSrc = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Crect width='300' height='300' fill='%23f0f0f0'/%3E%3C/svg%3E`;

	return (
		<div className="productCard">
			<div className="productCard__leftContent">
				{dateCreated < twoWeeksAgo ? "" : <span className="NewsSticker">New</span>}
				<div className="productCard__image">
					<LazyLoadImage
						src={image}
						alt={Name}
						effect="blur"
						width="100%"
						height="100%"
						placeholderSrc={placeholderSrc}
						threshold={100} // Загружать изображение когда оно в 100px от viewport
						wrapperClassName={`productCard__image-wrapper ${imageLoaded ? 'loaded' : ''}`}
						className="productCard__image-content"
						onLoad={() => setImageLoaded(true)}
					/>
				</div>
			</div>
			<div className="productCard__rightContent">
				<div className="productCard__info">
					<span className="productCard__catName">{catName}</span>
					<span className="productCard__prodName">{Name}</span>
				</div>

				<div className="btnMobOpenChar" onClick={(e) => { e.preventDefault(); setShowChar(!showChar) }}>
					{showChar ? "Collapse" : "Expand"}
				</div>
				<div className={"productCard__characteristics" + (showChar ? " showChar" : "")}>
					<div dangerouslySetInnerHTML={{ __html: descr }} />
				</div>
			</div>
		</div>
	)
}

export default ProductCard;