import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CatalogNav from "./CatalogNav";
import ProductCard from "./ProductCard";
import ContentLoader from "react-content-loader";
import Breadcrumbs from "../Breadcrumb";

const fetchProducts = async (page, urlPlus, productsPerPage) => {
  const response = await fetch(
    `https://resanta.org/wordpress/wp-json/wc/v3/products?per_page=${productsPerPage}&page=${page}${urlPlus}`,
    {
      headers: {
        Authorization:
          "Basic " +
          btoa(
            "ck_ad1ce0bd580ba47f8d8a07183206757f3cc5fbb0:cs_9b6b6dcebda9d9897fb362dbc746af065b78203d"
          ),
        "Content-Type": "application/json",
      },
    }
  );
  const total = response.headers.get("X-Wp-Total");
  const data = await response.json();
  
  // Используем оригинальные изображения
  const optimizedData = data.map(product => ({
    ...product,
    images: product.images?.map(image => ({
      ...image,
      src: image.src,
      srcset: image.srcset,
      sizes: '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
    }))
  }));

  return { data: optimizedData, total };
};

const CatalogPage = () => {
  const location = useLocation();
  const thisPage = location.state?.currentPage || 1;
  const [currentPage, setCurrentPage] = useState(thisPage);
  const [productsPerPage] = useState(5);
  const [jumpPage, setJumpPage] = useState("");
  const queryClient = useQueryClient();

  const { id } = useParams();
  let urlPlus = id ? `&category=${id}` : "";

  // Основной запрос данных
  const { data: productsData, isLoading } = useQuery({
    queryKey: ['products', currentPage, urlPlus],
    queryFn: () => fetchProducts(currentPage, urlPlus, productsPerPage),
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // Кэш действителен 5 минут
  });

  // Предзагрузка следующей страницы
  useEffect(() => {
    if (productsData?.data) {
      const totalPages = Math.ceil(productsData.total / productsPerPage);
      if (currentPage < totalPages) {
        queryClient.prefetchQuery({
          queryKey: ['products', currentPage + 1, urlPlus],
          queryFn: () => fetchProducts(currentPage + 1, urlPlus, productsPerPage)
        });
      }
    }
  }, [currentPage, productsData, queryClient, urlPlus, productsPerPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const handleJumpToPage = () => {
    const page = parseInt(jumpPage, 10);
    if (!isNaN(page) && page > 0 && page <= totalPages) {
      handlePageChange(page);
      setJumpPage("");
    } else {
      alert("Please enter a valid page number.");
    }
  };

  const totalPages = productsData ? Math.ceil(productsData.total / productsPerPage) : 0;

  const generatePagination = () => {
    const pages = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1, "...", totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pages.push(1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages);
      }
    }

    return pages;
  };

  const SkelArray = Array(3)
    .fill(null)
    .map((_, i) => (
      <ContentLoader
        speed={2}
        width={"100%"}
        height={408}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        key={i}
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="408" />
      </ContentLoader>
    ));

  const ProdArray = productsData?.data.map((item) => (
    <Link
      to={{ pathname: `/catalog/product/${item.id}` }}
      state={{ currentPage, id }}
      key={item.id}
    >
      <ProductCard
        catName={item.categories && item.categories[0] ? item.categories[0].name : "No Category"}
        Name={item.name}
        image={item.images && item.images[0] ? item.images[0].src : "placeholder.jpg"}
        descr={item.description ? item.description : "No description available"}
        date_created={item.date_created}
      />
    </Link>
  ));

  return (
    <div className="CatalogPage">
      <div className="container">
        <div className="navCategories">
          <CatalogNav />
        </div>
        <div className="producCards">
          <Breadcrumbs />
          {isLoading ? SkelArray : ProdArray}
        </div>
      </div>
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Prev
        </button>
        {generatePagination().map((page, index) =>
          page === "..." ? (
            <span key={index} className="pagination-ellipsis">
              ...
            </span>
          ) : (
            <button
              key={index}
              onClick={() => handlePageChange(page)}
              className={`pagination-button ${page === currentPage ? "active" : ""}`}
            >
              {page}
            </button>
          )
        )}
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
        <div className="jump-to-page">
          <input
            type="number"
            value={jumpPage}
            onChange={(e) => setJumpPage(e.target.value)}
            placeholder="Page #"
          />
          <button onClick={handleJumpToPage}>Go</button>
        </div>
      </div>
    </div>
  );
};

export default CatalogPage;
