// AboutUs.js
import React from 'react';
import firstImg from '../../assets/images/aboutUs/img1.png';
import secondImg from '../../assets/images/aboutUs/img2.png';

const AboutUs = () => {
  return (
    <section id="about-us" className="aboutUs">
      <div className="container flex row">
        <div className="aboutUs__leftContent flex column">
          <div className="flex row">
            <div className="aboutUs__card">
              <h2 className="titleSection">About us</h2>
            </div>
            <div className="aboutUs__card">
              <img src={firstImg} alt="" />
            </div>
          </div>
          <div className="aboutUs__bigCard">
            <div className="aboutUs__card">
              <img src={secondImg} alt="" />
            </div>
          </div>
        </div>
        <div className="aboutUs__rightContent flex column">
          <p className="aboutUs__textBold">
            Resanta is a globally recognized brand that continuously updates its product range with the latest technological advancements, offering customers state-of-the-art solutions. We are committed to ensuring that our customers can always rely on the reliability and efficiency of our equipment, which will help them achieve success in their business ventures. Our dedication to innovation and excellence has gained us the trust of businesses around the world, with various industries relying on our advanced technologies to optimize operations, increase productivity, and maintain competitiveness. With a strong focus on exceptional customer service, Resanta provides comprehensive assistance to its customers.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
